import { useMemo, useState } from "react"
import styled from "styled-components"

import {
  useFetchOrganization,
  useFetchOrganizations,
} from "src/data/organizations/queries/organizationQueries"
import { usePutTransferHomeToNewOrg } from "src/data/paradise/paradiseHomes/queries/paradiseHomeQueries"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MBanner } from "src/ui/MBanner/MBanner"
import { IMSelectResult, MSelect } from "src/ui/MSelect/MSelect"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

interface IOrganizationDropDownResult extends IMSelectResult {
  orgName?: string
}

const FORM_ID = "transfer-form"

export function ParadiseHomeTransferDialog({
  open,
  onClose,
  homeOwnerId,
  homeName,
  homeId,
}: {
  open: boolean
  onClose: () => void
  homeOwnerId: string
  homeName: string
  homeId: string
}) {
  const [searchedId, setSearchedId] = useState("")
  const [selectedOrg, setSelectedOrg] = useState<IOrganizationDropDownResult>({
    id: "",
    name: "",
  })
  const [transferStatus, setTransferStatus] = useState<
    "Transfer" | "Transfering" | "Confirm"
  >("Transfer")

  const putTransferHomeToNewOrg = usePutTransferHomeToNewOrg()

  const fetchCurrentOrganization = useFetchOrganization({
    id: homeOwnerId,
  })

  const fetchedOrganizations = useFetchOrganizations({
    params: {
      id: searchedId,
    },
  })

  function handleTransfer() {
    if (transferStatus === "Transfer") {
      setTransferStatus("Confirm")
      return
    }

    setTransferStatus("Transfering")

    putTransferHomeToNewOrg.mutate(
      {
        new_org_id: selectedOrg.id,
        homes: [homeId],
      },
      {
        onSuccess: () => {
          resetState()
          onClose()
        },
      }
    )
  }

  function resetState() {
    setSearchedId("")
    setSelectedOrg({
      id: "",
      name: "",
    })
    setTransferStatus("Transfer")
  }

  const dropdownValue = useMemo(
    () => ({
      id: selectedOrg.id,
      name: selectedOrg.name,
      orgName: selectedOrg.orgName,
    }),
    [selectedOrg]
  )

  const dropdownOptions =
    fetchedOrganizations.data?.organizations.map((o) => ({
      id: o.id,
      name: o.id,
      orgName: o.name,
    })) ?? []

  return (
    <MDialog
      open={open}
      title={`Transfer ${homeName}`}
      onClose={() => {
        onClose()
        resetState()
      }}
      formId={FORM_ID}
      confirmLabel={transferStatus}
      confirmButtonProps={{
        color: transferStatus === "Confirm" ? "destructive" : "default",
        loading: transferStatus === "Transfering",
      }}
    >
      <FormWrapper
        onSubmit={(e) => {
          e.preventDefault()
          handleTransfer()
        }}
        id={FORM_ID}
      >
        <MText variant="body">
          Transfer the home to a different organization
        </MText>
        {fetchCurrentOrganization.isError && (
          <MBanner type="warning">Could not get current organization</MBanner>
        )}
        <div>
          <MSelect
            value={dropdownValue}
            options={dropdownOptions}
            loading={
              fetchedOrganizations.isLoading ||
              fetchCurrentOrganization.isLoading
            }
            onSelected={(o) => {
              if (!o) return
              setSelectedOrg(o)
              setSearchedId("")
            }}
            onSearch={(s) => {
              setSearchedId(s)
            }}
            getOptionDisabled={(o) => o?.id === homeOwnerId}
            required
            label="Select organization"
          />
        </div>
        {selectedOrg.id && (
          <>
            <ConfirmationWrapper>
              <div>
                <MText variant="bodyS" color="secondary">
                  Current organization
                </MText>
                <MText variant="body">Id: {homeOwnerId}</MText>
                <MText variant="body">
                  Organization: {fetchCurrentOrganization.data?.name || "-"}
                </MText>
              </div>
              <div>
                <MText variant="bodyS" color="secondary">
                  New organization
                </MText>
                <MText variant="body">Id: {selectedOrg.id}</MText>
                <MText variant="body">
                  Organization: {selectedOrg.orgName}
                </MText>
              </div>
            </ConfirmationWrapper>

            <MBanner type="warning">
              If the organization is owned by a different user than the current
              organization both users subscriptions will be updated to reflect
              the changed home ownership
            </MBanner>
          </>
        )}
      </FormWrapper>
    </MDialog>
  )
}

const FormWrapper = styled.form`
  display: grid;
  gap: ${spacing.L};
`
const ConfirmationWrapper = styled(MBanner)`
  display: grid;
  gap: ${spacing.M};
`

import { useState } from "react"

import { ParadiseHomeTransferDialog } from "src/components/Paradise/ParadiseHomes/ParadiseHomeDetails/ParadiseHomeTransferDialog"
import { IParadiseHome } from "src/data/paradise/paradiseHomes/types/paradiseHomeQuerytypes"
import { MButton } from "src/ui/Button/MButton"

export function ParadiseHomeActions({ home }: { home: IParadiseHome }) {
  const [showTransferDialog, setShowTransferDialog] = useState(false)

  return (
    <>
      <MButton
        variant="subtle"
        size="small"
        onClick={() => setShowTransferDialog(true)}
      >
        Transfer
      </MButton>
      <ParadiseHomeTransferDialog
        open={showTransferDialog}
        homeOwnerId={home.owner.id}
        homeName={home.name}
        homeId={home.id}
        onClose={() => setShowTransferDialog(false)}
      />
    </>
  )
}

import { EmptyState } from "src/components/EmptyState"
import { ParadiseDetailsLayout } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseHomeActions } from "src/components/Paradise/ParadiseHomes/ParadiseHomeDetails/ParadiseHomeActions"
import { ParadiseHomeLogs } from "src/components/Paradise/ParadiseHomes/ParadiseHomeDetails/ParadiseHomeLogs/ParadiseHomeLogs"
import { ParadiseHomeMembers } from "src/components/Paradise/ParadiseHomes/ParadiseHomeDetails/ParadiseHomeMembers/ParadiseHomeMembers"
import { ParadiseHomeOccupancyGraph } from "src/components/Paradise/ParadiseHomes/ParadiseHomeDetails/ParadiseHomeOccupancyGraph/ParadiseHomeOccupancyGraph"
import { ParadiseHomeOverview } from "src/components/Paradise/ParadiseHomes/ParadiseHomeDetails/ParadiseHomeOverview/ParadiseHomeOverview"
import { DetailsHeading2 } from "src/components/Paradise/ParadiseSharedStyles"
import { LoadingWrapper } from "src/components/Paradise/sharedStyles"
import { useFetchParadiseHome } from "src/data/paradise/paradiseHomes/queries/paradiseHomeQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import Svg404 from "src/ui/icons/404.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

import { ParadiseHomeDevices } from "./ParadiseHomeDevices/ParadiseHomeDevices"

export function ParadiseHomeDetails({ homeId }: { homeId: string }) {
  const { t } = useTranslate()

  const fetchHome = useFetchParadiseHome({
    homeId: homeId,
  })

  if (fetchHome.isError) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  if (fetchHome.isLoading) {
    return (
      <LoadingWrapper>
        <MSkeleton height={40} width="30ch" />
        <MSkeleton height={20} width="40ch" />
      </LoadingWrapper>
    )
  }

  const home = fetchHome.data

  return (
    <ParadiseDetailsLayout
      meta={<DetailsHeading2>{home.name}</DetailsHeading2>}
      banner={
        home.deleted && (
          <MBanner type="error" fullWidth>
            Home is deleted
          </MBanner>
        )
      }
      actions={<ParadiseHomeActions home={home} />}
      left={<ParadiseHomeOverview home={home} />}
      right={
        <Tabs
          labels={[
            {
              id: "general",
              label: "General",
            },
            {
              id: "home_logs",
              label: "Home logs",
            },
            {
              id: "occupancy_graph",
              label: "Occupancy graph",
            },
          ]}
        >
          <TabPanel>
            <ParadiseHomeMembers orgId={home.owner.id} homeId={homeId} />
            <ParadiseHomeDevices homeId={homeId} />
          </TabPanel>
          <TabPanel>
            <ParadiseHomeLogs homeId={homeId} />
          </TabPanel>
          <TabPanel>
            <ParadiseHomeOccupancyGraph homeId={homeId} />
          </TabPanel>
        </Tabs>
      }
      breadcrumbs={[
        {
          label: "Homes",
          to: Routes.ParadiseHomes.location(),
        },
        {
          label: home.id,
          to: Routes.ParadiseHome.location(home.id),
        },
      ]}
      breadcrumbCopyValue={home.id}
    />
  )
}
